export default {
    name: "Accounts",
    created() {
        this.$nextTick(function () {

        });
    },
    mounted() {
        this.$nextTick(function () {
            // this.$$.navbarAnimation()
        })
    },
    data() {
        return {
         
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {

    }
};